/* eslint-disable */
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
  <template v-if="!loading">
  <div id="pdf_loader">
    <table
      align="left"
      cellspacing="0"
      border="0"
      >
      <colgroup width="65"></colgroup>
      <colgroup span="7" width="64"></colgroup>
      <colgroup width="79"></colgroup>
      <colgroup width="64"></colgroup>
      <colgroup width="10"></colgroup>
      <colgroup span="2" width="57"></colgroup>
      <colgroup width="10"></colgroup>
      <colgroup width="39"></colgroup>
      <colgroup span="5" width="57"></colgroup>
      <colgroup width="67"></colgroup>
      <tr>
        <td style="border-left: 3px solid #000000; border-right: 3px solid #000000"   align="center" bgcolor="#000000">
          <img src="/images/kag_logo.png" width=192 height=67>
        </td>
        <td style="border-left: 3px solid #000000; border-right: 3px solid #000000" colspan=19 align="center" bgcolor="#000000">
          <b>
          <font size=5 color="#FFFFFF">
            Daily Activity Report - SALES
            <br />
            <b><font size=3 color="#FFFFFF">{{ formatFullDate(date) }}</font></b>
            <br />
            <font size=2>{{ summary.number_working_days_in_month }} working days; {{ summary.working_days_completed_in_month }} worked; {{ summary.working_days_remaining_in_month }} remaining</font>
          </font></b></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td style="border-bottom: 1px solid #000000; border-left: 3px solid #000000" height="16" align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><b><font size=4 color="#FFFFFF"><br></font></b></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
        <td align="left" bgcolor="#333333"><br></td>
        <td style="border-left: 2px solid #000000; border-right: 0px solid #000000" align="left" bgcolor="#333333"><br></td>
        <td style="border-left: 2px solid #000000" colspan=2 align="center" bgcolor="#333333" sdnum="1033;1033;MMMM D\, YYYY;@"><b><font color="#FFFFFF">Stone&nbsp;Eagle</font></b></td>
        <td style="border-right: 0px solid #000000;" align="left" bgcolor="#333333"><br></td>
        <td style="border-left: 3px solid #000000;" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
      </tr>
      <tr>
        <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="30" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DATE</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">PHONE</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">TRAFFIC</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">CLOSE&nbsp;%</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DELIVERED</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">NEW</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">USED</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">GROSS</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">AVERAGE</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 0px solid #000000" align="center" bgcolor="#333333"><b><br></b></td>
        <td style="border-top: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DEALS</font></b></td>
        <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">P/U</font></b></td>
        <td style="border-bottom: 2px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000" align="center" bgcolor="#333333"><b><br></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000; border-left: 3px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Phone</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Traffic</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Sales</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Gross</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Closing&nbsp;%</font></b></td>
        <td align="left"><br></td>
      </tr>

      <template v-for="(item, index) in items">
        <!-- FIRST DATA ROW HERE -->
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-top: 0px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000" rowspan=3 align="center" valign=middle :bgcolor="rowBgColor(index)"><b>{{ item.account_abbrev }}</b></td>
          <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="44125" sdnum="1033;1033;D-MMM"><b>{{ formatAbbreviatedDate(item.dar.activity_date, false, '-') }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="11" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.phone) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="18" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.traffic) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="0.56" sdnum="1033;0;0%"><b>{{ item.dar.closing_percent }}%</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="10" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.delivered) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="7" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.delivered_new) }} </b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="3" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.delivered_used) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="30398" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(item.dar.gross) }}</b></td>
          <td style="border-right: 0px solid #000000; border-left: 0px solid #000000; border-bottom: 1px solid #000000;;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 0px solid #000000; border-left: 2px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-right: 2px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <template v-if="showPaceRow()">
            <td style="border-top: 0px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_phone)">{{ formatNumber(item.monthly_dar.mom_phone) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_traffic)">{{ formatNumber(item.monthly_dar.mom_traffic) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_delivered)">{{ formatNumber(item.monthly_dar.mom_delivered) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_gross)">{{ formatNumber(item.monthly_dar.mom_gross) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_closing_percent)">{{ formatNumber(item.monthly_dar.mom_closing_percent) }} %</font></b></td>
            <td align="left"><br></td>
          </template>
          <template v-else>
            <td style="border-top: 0px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td align="left"><br></td>
          </template>
        </tr>
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b>Totals</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="242" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.phone) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="405" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.traffic) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="0.348148148148148" sdnum="1033;0;0%"><b>{{ item.monthly_dar.closing_percent }}%</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="141" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.delivered) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="96" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.delivered_new) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="45" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.delivered_used) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="438100" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(item.monthly_dar.gross) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="3107.09219858156" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(item.monthly_dar.gross_average) }}</b></td>
          <td style="border-left: 2px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;&quot;$&quot;#,##0"><b><br></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="127" sdnum="1033;"><b>{{ formatNumberNoZero(item.dar.se_deals) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="profitBgColor(item.dar.se_pu, index)" sdval="3312" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPriceNoZero(item.dar.se_pu) }}</b></td>
          <template v-if="showPaceRow()">
            <td align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_phone)">{{ formatNumber(item.monthly_dar.yoy_phone) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_traffic)">{{ formatNumber(item.monthly_dar.yoy_traffic) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_delivered)">{{ formatNumber(item.monthly_dar.yoy_delivered) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_gross)">{{ formatNumber(item.monthly_dar.yoy_gross) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_closing_percent)">{{ formatNumber(item.monthly_dar.yoy_closing_percent) }} %</font></b></td>
            <td align="left"><br></td>
          </template>
          <template v-else>
            <td align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td align="left"><br></td>
          </template>
        </tr>
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;1033;D-MMM"><b><br></b></td>
          <td style="border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
          <td style="border-bottom: 3px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b>Pace</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#FFFFCC" sdval="211.5" sdnum="1033;0;#,##0"><b>{{ formatNumber( item.monthly_dar.delivered_pace) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#FFFFCC" sdval="144" sdnum="1033;0;0"><b>{{ formatNumber( item.monthly_dar.delivered_new_pace) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#FFFFCC" sdval="67.5" sdnum="1033;0;0"><b>{{ formatNumber( item.monthly_dar.delivered_used_pace) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-right: 2px solid #000000;white-space: nowrap" align="center" valign=middle bgcolor="#FFFFCC" sdval="657150" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice( item.monthly_dar.gross_pace) }}</b></td>
          <td style="border-right: 0px solid #000000;border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-bottom: 3px solid #000000; border-left: 2px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-left: 2px solid #000000;border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-right: 2px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><br></b></td>
          <td style="border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-right: 3px solid #000000;white-space: nowrap" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td align="left"><br></td>
        </tr>
        <template v-if="item.account_abbrev === 'NNB'">
          <tr style="height: 155px"  :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id" />
          <tr class="with-page-break" :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
            <td style="border-left: 3px solid #000000; border-right: 3px solid #000000;white-space: nowrap"   align="center" bgcolor="#000000">
              <img src="/images/kag_logo.png" width=192 height=67>
            </td>
            <td style="border-left: 3px solid #000000; border-right: 3px solid #000000;white-space: nowrap" colspan=19 align="center" bgcolor="#000000">
              <b>
              <font size=5 color="#FFFFFF">
                Daily Activity Report - SALES
                <br />
                <b><font size=3 color="#FFFFFF">{{ formatFullDate(date) }}</font></b>
                <font size=2>&nbsp;-&nbsp;{{ summary.number_working_days_in_month }} working days; {{ summary.working_days_completed_in_month }} worked; {{ summary.working_days_remaining_in_month }} remaining</font>
              </font></b></td>
            <td align="left"><br></td>
          </tr>
          <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
            <td style="border-bottom: 1px solid #000000; border-left: 3px solid #000000;white-space: nowrap" height="16" align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><b><font size=4 color="#FFFFFF"><br></font></b></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><font color="#FFFFFF"><br></font></td>
            <td align="left" bgcolor="#333333"><br></td>
            <td style="border-left: 2px solid #000000; border-right: 0px solid #000000;white-space: nowrap" align="left" bgcolor="#333333"><br></td>
            <td style="border-left: 2px solid #000000;white-space: nowrap" colspan=2 align="center" bgcolor="#333333" sdnum="1033;1033;MMMM D\, YYYY;@"><b><font color="#FFFFFF">Stone Eagle</font></b></td>
            <td style="border-right: 0px solid #000000;white-space: nowrap" align="left" bgcolor="#333333"><br></td>
            <td style="border-left: 3px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
          </tr>
          <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
            <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000;white-space: nowrap" height="30" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DATE</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">PHONE</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">TRAFFIC</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">CLOSE %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DELIVERED</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">NEW</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">USED</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">GROSS</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">AVERAGE</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 2px solid #000000; border-right: 0px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-left: 2px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DEALS</font></b></td>
            <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 2px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">P/U</font></b></td>
            <td style="border-bottom: 2px solid #000000; border-left: 0px solid #000000; border-right: 0px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000; border-left: 3px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Phone</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Traffic</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Sales</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Gross</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000;white-space: nowrap" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Closing&nbsp;%</font></b></td>
            <td align="left"><br></td>
          </tr>
        </template>
        
      </template>

      <!-- END OF DATA -->
      <tr>
        <td style="border-top: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan=20 height="23" align="center" valign=middle bgcolor="#333333"><b><font size=3 color="#FFFFFF">KAG Totals</font></b></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle bgcolor="#FFFFFF"><b>Day</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="108" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_phone) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="211" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_traffic) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="0.442222222222222" sdnum="1033;0;0%"><b>{{ summary.daily_closing_percent }}%</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="92" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_delivered) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="67" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_delivered_new) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="25" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_delivered_used) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="281412" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.daily_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="3058.82608695652" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.daily_avg_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><br></b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><br></b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><b><br></b></td>
        <template v-if="showPaceRow()">
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_phone)">{{ formatNumber(summary.mom_phone) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_traffic)">{{ formatNumber(summary.mom_traffic) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_delivered)">{{ formatNumber(summary.mom_delivered) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_gross)">{{ formatNumber(summary.mom_gross) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_closing_percent)">{{ formatNumber(summary.mom_closing_percent) }} %</font></b></td>
          <td align="left"><br></td>
        </template>
        <template v-else>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b></b></td>
          <td align="left"><br></td>
        </template>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle><b>MTD</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="2335" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_phone) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="4778" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_traffic) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="0.474071533287137" sdnum="1033;0;0%"><b>{{ summary.monthly_closing_percent }}%</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="2113" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_delivered) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="1373" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_delivered_new) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="740" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_delivered_used) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="7237760" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.monthly_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="3425.34784666351" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.monthly_avg_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="2035" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.se_deals) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="3755.94444444444" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.se_avg_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <template v-if="showPaceRow()">        
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_phone)">{{ formatNumber(summary.yoy_phone) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_traffic)">{{ formatNumber(summary.yoy_traffic) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_delivered)">{{ formatNumber(summary.yoy_delivered) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_gross)">{{ formatNumber(summary.yoy_gross) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_closing_percent)">{{ formatNumber(summary.yoy_closing_percent) }} %</font></b></td>
          <td align="left"><br></td>
        </template>
        <template v-else>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b></b></td>
          <td align="left"><br></td>
        </template>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle><b>Pace</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="3502.5" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_phone) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="7167" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_traffic) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdnum="1033;0;0%"><b><br></b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="3169.5" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_delivered) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="2059.5" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_delivered_new) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="1110" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_delivered_used) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="10856640" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.pace_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="3425.34784666351" sdnum="1033;0;&quot;$&quot;#,##0"><b>{{ formatPrice(summary.pace_avg_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-bottom: 3px solid #000000; border-left: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><br></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td colspan="9">
          Powered by Dealer Insights, LLC.
        </td>
      </tr>
    </table>
  </div>
  </template>
  </v-container>
</template>
<script>
  import DarService from '@/services/DarService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'SalesDar',
    components: {
    },
    mixins: [formatterMixin],
    data () {
      return {
        items: [],
        loading: true,
        error: '',
        date: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    created () {
      this.loadDars()
    },
    methods: {
      loadDars: function () {
        this.loading = true
        this.items = []
        this.summary = []
        
        var data = {
          dgid: this.$route.query.dgid,
        }

        DarService.getSaleDars(data)
          .then(response => {
            this.items = response.data.dars
            this.date = this.items[0].dar.activity_date
            this.summary = response.data.summary
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      rowBgColor(index) {
        if (index%2 == 0)
          return "#C0C0C0"
        else
          return false;
        return "#FFFFFF"
      },
      profitBgColor (profit, index) {
        if (profit === null)
          return this.rowBgColor(index)
        if (profit >= 3000) 
          return "#C7EFCF"
        if (profit >= 2500 && profit < 3000)        
          return "#C6D9F0"
        if (profit >= 2000 && profit < 2500)        
          return "#FAD5B6"
        if (profit < 2000)
          return "#FDC7CE"
      },
      comparisonColor (percentChange) {
        if (percentChange > 1)
          return "#1AAF54"
        if (percentChange >= -9 && percentChange <= 1)
          return "#976513"
        if (percentChange < -9)
          return "#FB0219"
      },
      showPaceRow () {
        const [year, month, day] = this.date.split('-')
        if (day < 9)
          return false
        else
          return true
      }
    },
  }
</script>
<style>
  .with-page-break {
    page-break-before: always;
    page-break-after: always;
  }
  .footer {
    position: fixed;
    bottom: 0;
  }
  td {
    white-space: nowrap
  }
</style>